
.list-div{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border: 1px solid rgb(221, 221, 221);
    border-radius: 10px;
    width: 150px;
     height: 80px;
    cursor: pointer;
    transition-property: transform;
    transition-duration: 1s;
    margin: 15px 15px
    
}

.list-div:hover{
    cursor: pointer;
    transform: scale(1.1) ;
    border: 1px solid green
}

.top{
display: flex;
justify-content: space-between;
}

.top-second{
    display: flex;
    justify-content: space-between;
   flex-direction: row;
   align-items: center;
   padding: 0 0 10px;
}

.show-input{
    min-width: 100px;
    border-radius: 3px;
    margin-left: 4px;
    margin-right: 10px;
    padding-top: 3px;
    padding-left: 7px;
    border: 1px solid #ddd;
    height: 25px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    line-height: 1.2em;
}
/* .ant-table-cell{
    line-height: 0.6;
} */
.wrap-adjusted-table{
    /* style={{ display: 'flex', padding: 5, marginTop: 5, height: '27vh' }}; */
    display: flex;
    padding: 5px;
    margin-top: 5px;
    height: 27vh;
    max-width: 100vw;
}

.table_production{
    max-width: 100%;
}
.adjusted{
    flex : 1;
    background: #fff;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 205;
}
.top-select{
    width: calc(100% - 60px);
    /* margin-left: 4px; */
    /* margin-right: 20px; */
}
.wrap-machine{
    /* background-color: rgb(244, 245, 245); */
    background-color: #fff;
}
.wrap-machine::-webkit-scrollbar{
    display: none;
}

@media only screen and (min-width : 300px) and (max-width : 1200px) {
    /* .top{
        flex-direction: column;
        align-items: baseline;
    }
    .top-first{
        flex-wrap: wrap;
    }
    .top-select{
        width: 110px;
    } */
    .span{
        width: 83px;
        display: flex;
        justify-content: center;
    }
    /* .top-second{
        flex-wrap: wrap;
    } */
    .wrap-adjusted-table{
        flex-direction: column;
    }
    .table{
    }
    /* .show-input{
        width: 110px;
    }  */
    
}
